.new-installer-form-container {
    padding-left: 2vw;
    padding-bottom: 2vw;
    margin: 5vw 7vw 2vw 10vw;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 1%;

}

@media only screen and (max-width: 575px) {
    .new-installer-form-container-responsive {
        margin: 4% 2% 2% 2% !important;
    }
}

.btn-container{
    margin-top: 6%;
    display: flex;
    justify-content: space-evenly; 
}