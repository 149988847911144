html * {
    font-family: 'Montserrat', sans-serif !important;

}

[class*="jss"] {
    z-index: 1 !important;
}

.tableTitle {
    margin-top: 3.6vh;
    margin-bottom: 5vh !important;
    display: flex;
    justify-content: center;
    color: #e85112 !important;
    font-size: large;
    cursor: default;
}

.test {
    display: flex;
    justify-content: center;
    color: #e85112 !important;
    font-size: large;
    font-weight: bold;
}

@media only screen and (max-width: 575px) {
    .titleContainerResponsive {
        width: 90% !important;
        display: flex;
        justify-content: space-between;
    }
}

.titleContainer {
    width: 58%;
    display: flex;
    justify-content: space-between;
}

.titleBack {
    display: flex;
    justify-content: center;
    color: #e85112 !important;
    font-size: large;
    cursor: default;
}

.back-button {
    margin-top: 4vh;
}

.subscriptionInfo {
    display: flex;
    justify-content: space-evenly;
}

.content {
    padding: 2% 10% 2% 10%;
    width: 100%;
    background-color: white;
}

.contentResponsive {
    padding: 2% 4% 2% 4%;
    width: 100%;
    background-color: white;
}

[class^="MUIDataTableHeadCell-"]{
    font-weight: bold !important;
    white-space: nowrap !important;
}

.headerContainer {
    height: 68px !important;
    position: fixed;
    width: 90%;
    z-index: 10;
}

.headerResponsive {
    height: 68px !important;
    display: flex !important;
    justify-content: space-between !important;
    position: fixed;
    width: 90%;
    z-index: 10;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

td.ant-table-column-sort {
    background: inherit !important;
}

.cell {
    display: flex;
    justify-content: space-between;
}

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
}

@media only screen and (max-width: 575px) {
    .ant-menu-dark.ant-menu-inline, .ant-menu-dark.ant-menu-vertical, .ant-menu-dark.ant-menu-vertical-left, .ant-menu-dark.ant-menu-vertical-right {
        border-right: 0;
    }
}

html {
    overflow: scroll;
}

::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent; /* make scrollbar transparent */
}

.ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
}

.ant-layout-sider-children {
    height: 100%;
    margin-top: -0.1px;
    padding-top: 0.1px;
    position: fixed;
    width: 200px;
    min-width: 200px;
    background: #f09169 !important;
    max-width: 200px;
}

@media only screen and (max-width: 575px) {
    .ant-layout-sider-children {
        height: 100%;
        margin-top: -0.1px;
        padding-top: 0.1px;
        position: fixed;
        width: 200px;
        min-width: 200px;
        background-color: transparent !important;
        max-width: 200px;
    }
}

@media only screen and (max-height: 414px) {
    .titleContainerHeigth {
        width: 75% !important;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }    

    .ant-layout-sider-children {
        margin-top: 10% !important;
        height: 100%;
        padding-top: 1px;
        position: fixed;
        width: 200px;
        min-width: 200px;
        background-color: transparent !important;
        max-width: 200px;
    }
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark .ant-menu-item-group-title, .ant-menu-dark .ant-menu-item > a, .ant-menu-dark .ant-menu-item > span > a {
    color: rgb(0 0 0) !important;
    font-size: 11pt;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    color: rgba(255, 255, 255, 0.65);
    background: #f09169 !important;
}

.ant-menu-item .ant-menu-item-icon + span, .ant-menu-submenu-title .ant-menu-item-icon + span, .ant-menu-item .anticon + span, .ant-menu-submenu-title .anticon + span {
    opacity: 1;
    color: black !important;
    font-size: 11pt !important;
}

.ant-menu-inline .ant-menu-submenu-title {
    padding-right: 34px;
    color: black !important;
}

.MuiListItem-gutters {
    padding-left: 2px !important;
    padding-right: 16px;
}

.MuiButtonBase {
    height: 54px !important;
}

.MuiAppBar-colorPrimary {
    background-color: #fff !important;
    z-index: 1000 !important;
}

.ant-btn-primary {
    color: #fff;
    background: #e85112 !important;
    border-color: #e85112 !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #fff !important;
    background: #f09169 !important;
    border-color: #f09169 !important;
}

.antd-btn-default {
    color: #fff;
    border-color: #e85112 !important;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-default:hover, .ant-btn-default:focus {
    color: #e85112 !important;
    background: #fff !important;
    border-color: #e85112 !important;
}

.ant-divider-horizontal{
    display: flex;
    margin: 16px 0;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    text-align: center;
    border-top: 0;
    border-top-color: #bcbcbc !important;/* rgba(0, 0, 0, 0.06);*/
    align-items: flex-start !important;
}
