.home-icon-card {
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiPaper-elevation1 {
    box-shadow: 0 0 0 0 !important;
}

.card-logo {
    max-width: 200px;
    min-width: 200px;
    max-height: 200px;
    min-height: 200px;
}

.card-content {
    display: flex;
    justify-content: center;
}

.card-buttons {
    display: flex !important;
    justify-content: space-around !important;
    flex-wrap: wrap;
}

.z-button, .z-button.disabled {
    margin-bottom: 5px !important;
    background: transparent;
    color: #e85112 !important;
    /*-webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);*/
    /*box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);*/
    border: 1px solid #e85112 !important;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    width: 100%;
    max-height: 50px !important;
}
.z-button:hover, .z-button.disabled:hover {
    background: black !important;
    color: #e85112;
    /*-webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);*/
    /*box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);*/
    border: 1px solid #e85112;
}

.z-button.active, .z-button:active, .z-button:focus,
.z-button.disabled.active,
.z-button.disabled:active,
.z-button.disabled:focus {
    background: black !important;
    color: #e85112 !important;
    /*-webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);*/
    /*box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);*/
    border-color: transparent;
}