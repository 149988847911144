.tableFilterDiv {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}

.tableFilterInput {
    width: 200px;
    margin-bottom: 10px;
    display: block
}

.alarmsTable {
    margin-top: 5vh;
}

.dark-row{
    background-color: #fafafa;
}

.light-row {
    
}

.details {
    display: flex;
    justify-content: center;
    align-items: center;
}

.details-icon {
    margin-right: auto;
    float: left;
    margin-top: 4px;
}