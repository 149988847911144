.summary-cards {
	display: flex;
	justify-content: space-around;
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
	border:0;
	margin-top: 5%;
	width: 100%;
}

.maintenance-container {
	display: flex;
	justify-content: center;
	padding-top: 5%;
	padding-bottom: 5%;
}