.dark-row{
  background-color: #fafafa;
}

.light-row {

}
.verticalBorder th {
  border: 1px solid rgba(224, 224, 224, 1);
}

[class^="MUIDataTableHeadCell-fixedHeader-"] {
  top: 0;
  z-index: 0 !important;
  position: inherit !important;
  background-color: #fff;
}

[class^="MUIDataTable-"] {
  padding: 2% !important;
}
/*
[class*=jss] {
  padding-left: 2% !important;
  padding-right: 2% !important;
}
*/
.MuiTableCell-head {
  font-weight: 500;
  line-height: 1.5rem;
  background-color: #f09169 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  white-space: nowrap !important;
}

.MuiTableRow-root:nth-child(2n) {
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
  background-color: #f5f5f5 !important;
}

.custom-tag {
  text-align: center;
  min-width: 90px;
  max-height: 22px;
  overflow: hidden;
}
