.alarm-container {
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5%;
}

.tag-container {
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
}

.ant-badge-status-success {
    width: 20px;
    height: 20px;
}

.ant-badge-status-error {
    width: 20px;
    height: 20px;
}

.ant-badge-status-default {
    width: 13px;
    height: 13px;
}

.blinking {
    animation: opacity 1s ease-in-out infinite;
    opacity: 1;
}

@keyframes opacity {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0
    }
    100% {
        opacity: 1;
    }
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}