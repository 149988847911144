.form-container-sub {
    padding-top: 2%;
    padding-left: 3%;
    padding-bottom: 2%;
    margin: 4% 15% 2% 15%;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 1%;
    display: flex;  
    flex: 1;
    justify-content:center;
}

@media only screen and (max-width: 575px) {
    .my-form-container-sub-responsive {
        padding-left: 1% !important;
        padding-right: 1% !important;
        margin: 4% 0% 2% 0% !important;
        width: 100% !important;
    }
}

.divider {
    font-weight: 800 !important;
}

.btn-container{
    margin-top: 6%;
    display: flex;
    justify-content: space-evenly; 
}

.selectdiv {
    display: flex;
    justify-content: center;
}