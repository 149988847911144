.subscriptionsTable {
    margin-top: 5vh;
}

.dark-row{
    background-color: #fafafa;
}

.light-row {
    
}
