.my-adduser-container {
    padding-left: 4%;
    padding-bottom: 2%;
    margin: 4% 15% 2% 15%;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 1%;
}

@media only screen and (max-width: 575px) {
    .my-adduser-responsive {
        margin: 4% 2% 2% 2% !important;
    }
}

.btn-container{
    margin-top: 6%;
    display: flex;
    justify-content: space-evenly; 
}