.my-signalconfiguration-container {
    padding: 2% 10% 2% 10%;
    margin: 2% 15% 6% 15%;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 1%;
}

@media only screen and (max-width: 575px) {
    .my-signalconfiguration-container-responsive {
        margin: 4% 2% 2% 2% !important;
    }
}

.my-option-container {
    margin-top: 5%;
    display: flex;
    justify-content: center;
}

.btn-container{
    margin-top: 6%;
    display: flex;
    justify-content: space-evenly; 
}

.card-opt{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.p-style{
    font-size: 16px !important;
    display: flex;
    justify-content: space-between;
}

.card-style{
    display: flex; 
    justify-content: flex-start; 
    align-items: center; 
    margin-top: 5%; 
    height: 150px !important;
    width: 450px !important; 
    border: 1px solid #d9d9d9; 
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ant-radio-button-wrapper:hover {
    color: #e85112 !important;
    border-color: #e85112 !important;
}

.ant-radio-button-wrapper-checked{
    color: #fff !important;
    background-color: #e85112 !important;
    border-color: #e85112 !important;
}

.ant-switch-checked {
    background-color: #e85112 !important;
}

.ant-ribbon {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: absolute;
    top: 8px;
    height: 22px;
    padding: 0 8px;
    color: #fff;
    line-height: 22px;
    white-space: nowrap;
    background-color: #e85112 !important;
    border-radius: 2px;
}

.summary-container{
    margin-top: 6%;
    display: flex;
    justify-content: space-evenly; 
    align-items: center;
}

.badge-container{
    margin-bottom: 10%;
    margin-top: 5%;
    border: 1px solid #d9d9d9;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}